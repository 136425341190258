<script>
  import Icons from "$lib/main/components/Icons.svelte";

  export let addClass = "";
  export let link = undefined;
  export let icon = undefined;
  export let onlyIcon = null;
  export let disabled = false;
  export let type = "button";
  export let title = null;
  export let iconStroke = "2";
  export let iconSize = "16";
  export let target = null;
</script>

{#if link}
  <a href={link} class={ "button-link" + (addClass ? " " + addClass : "")} class:icon {title} {target}>
    <span><slot /></span>
    {#if icon}<Icons name={icon} strokeWidth={iconStroke} width={iconSize + "px"} height={iconSize + "px"} />{/if}
  </a>
{:else}
  <button {type} {disabled} {title} class={addClass} class:icon class:onlyIcon on:click>
    {#if onlyIcon}
      {#if icon}<Icons name={icon} strokeWidth={iconStroke} width={iconSize + "px"} height={iconSize + "px"} />{/if}
    {:else if addClass.includes("lucide-icon")}
      <!-- TODO: temp for switching over to Lucide icons -->
      <slot />
    {:else}
      <span><slot /></span>
      {#if icon}<Icons name={icon} strokeWidth={iconStroke} width={iconSize + "px"} height={iconSize + "px"} />{/if}
    {/if}
  </button>
{/if}