<script>
  import { project } from "$lib/services/store";
  export let operator = null;
</script>

<!-- Only one Ribbon will be shown at a time, so order these in priority  -->

{#if operator["visuals"]?.[$project.key]?.[$project.settings.market]?.["ribbon_text"]}
  <div class="ribbon">
    <span
      style={`background-color: rgba(${
        operator.visuals?.[$project.key]?.[$project.settings.market]
          .ribbon_bg_color
      })` +
        `; color: rgba(${
          operator.visuals?.[$project.key]?.[$project.settings.market]
            .ribbon_text_color
        })`}
      >{operator["visuals"][$project.key][$project.settings.market][
        "ribbon_text"
      ]}</span
    >
  </div>
{:else if operator?.data?.deposit && operator?.data?.deposit[$project.settings.market]?.includes("Interac") && $project.settings.market == "ca"}
  <div class="ribbon interac">
    <img
      loading="lazy"
      src={$project.data.imageFolders.assets +
        "interac.png?w=50&h=50&q=80&auto=format"}
      alt={"Interac deposit available at " + operator.name}
      title={"Interac deposit available at " + operator.name}
    />
  </div>
{/if}

<style>
  .ribbon {
    position: absolute;
    overflow: hidden;
    width: 75px;
    height: 75px;
    text-align: right;
    margin-left: 0;
    margin-top: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    top: 0;
    left: 0;
  }
  .ribbon span {
    font-size: 10px;
    font-weight: 500;
    color: #000;
    text-align: center;
    line-height: 21px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    width: 100px;
    display: block;
    background-color: #65c82d;
    position: absolute;
    top: 15px;
    left: -24px;
  }
</style>
