<script>
  export let bonusData;
  export let marketSetting;
  import { GaugeCircle } from "lucide-svelte";
  import Translate from "$lib/admin/components/blocks/helpers/Translate.svelte";

  let bonus_turnover = bonusData?.casino?.[marketSetting]?.["bonus_turnover"];
  let bonus_amount = bonusData?.casino?.[marketSetting]?.["bonus_amount"];
</script>

<div class="bonus-turnover">
  <div class="bonus-icon-mobile">
    <GaugeCircle size="20" strokeWidth="1" />
  </div>
  <div class="bonus-icon-desktop">
    <GaugeCircle strokeWidth="1" />
  </div>
  {#if bonus_amount}
    <Translate text="Bonus wagering requirements" />:
    <!-- <Translate text="Bonus wagering requirements" />: -->
    {#if bonus_turnover != null && bonus_turnover != undefined && bonus_turnover != 0}
      <strong>{bonus_turnover}x</strong>
    {:else if bonus_turnover == 0}
      <span><strong>?</strong></span>
    {/if}
  {:else}
    <span><Translate text="Bonus not available" /></span>
  {/if}
</div>

<style>
  .bonus-turnover {
    display: flex;
    gap: 5px;
    align-items: center;
    font-weight: 300;
    font-size: 13px;
    @media screen and (min-width: 992px) {
      font-size: 13px;
    }
  }
  .bonus-icon-mobile {
    display: flex;
    @media screen and (min-width: 992px) {
      display: none;
    }
  }
  .bonus-icon-desktop {
    display: none;
    @media screen and (min-width: 992px) {
      display: flex;
    }
  }
</style>
