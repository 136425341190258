import { translate } from "./translate";

function isDirectCase(value, unitKey) {
  return value === 0 && (unitKey === "min" || unitKey === "sec");
}

function calculateAndFormatValue(value, unitKey, options: { lang: string }) {
  let mutableValue = value;
  const { lang } = options;

  if (unitKey === "min") {
    if (mutableValue < 1 && mutableValue > 0) {
      mutableValue = Math.round(mutableValue * 60);
      unitKey = "sec";
    } else if (mutableValue >= 120) {
      const hours = Math.ceil(mutableValue / 60);
      mutableValue = hours % 1 === 0 ? hours : hours.toFixed(1);
      unitKey =
        hours % 1 === 0 ? translate("hours", lang) : translate("hour", lang);
    } else if (mutableValue > 1) {
      mutableValue = Math.ceil(mutableValue);
    }
  }
  const unitTranslation = unitKey
    ? unitKey === "x"
      ? unitKey
      : ` ${translate([unitKey], lang)}`
    : "";
  return `${mutableValue}${unitTranslation ? "" : ""}${unitTranslation}`;
}

export function formatValue(
  value: number | string | null,
  unitKey: string,
  options: { paymentMethod?: string; lang: string } = { lang: "en" }
): string {
  if (value === "-" || value === undefined || value === null || value === "") {
    return "-";
  }

  let formattedValue = isDirectCase(value, unitKey)
    ? translate("Direct", options.lang)
    : calculateAndFormatValue(value, unitKey, options);

  if (options.paymentMethod) {
    formattedValue += ` (${options.paymentMethod})`;
  }

  return formattedValue;
}
