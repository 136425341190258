// Date converting
const months = [
  "january",
  "february",
  "march",
  "april",
  "may",
  "june",
  "july",
  "august",
  "september",
  "october",
  "november",
  "december",
];
const monthsTranslations = [
  ["january", "januari"],
  ["february", "februari"],
  ["march", "mars"],
  ["april"],
  ["may", "maj"],
  ["june", "juni"],
  ["july", "juli"],
  ["august", "augusti"],
  ["september"],
  ["october", "oktober"],
  ["november"],
  ["december"],
];

export function dateFormatter(date, region = "en-GB") {
  const d = new Date(date);

  if (region == "sv-SE") {
    // returns date format '31 aug 2023'
    return d
      .toLocaleDateString(region, {
        day: "numeric",
        month: "short",
        year: "numeric",
      })
      .replaceAll(".", "");
  }
  // returns date format '2023-08-31'
  return d.toLocaleDateString(region);
}

export function monthNumberToMonthLetters(monthNumber, capitalize = false) {
  const monthsCapitalized = months.map(
    (m) => m.charAt(0).toUpperCase() + m.slice(1)
  );
  if (capitalize) return monthsCapitalized[monthNumber - 1];
  return months[monthNumber - 1];
}

export function monthLettersToMonthNumber(monthLetters) {
  let index = monthsTranslations.findIndex((x) =>
    x.includes(monthLetters.toLowerCase())
  );
  return index + 1;
}

// Removes non-alphanumeric characters and makes it all lowercase
// NOT CURRENTLY USED (remove this line if this is actually used anymore)
export function nameToLower(name) {
  let nameLower = "";
  for (var i = 0; i < name.length; i++) {
    if (!name[i].match(/[a-z]/i) && !name[i].match(/[0-9]/i)) {
      nameLower += "-";
    } else {
      nameLower += name[i];
    }
  }
  return nameLower.toLowerCase();
}

// Parses datetime fetched from Supabase
export function parseDatetime(datetime) {
  if (!datetime) {
    return "";
  }
  return `${datetime.split(":")[0]}:${datetime.split(":")[1]}`;
}

// compare two arrays
export function arraysSameContent(array1, array2) {
  if (!array1 || !array2) {
    return false;
  }
  return (
    array1.length === array2.length &&
    array1.every((element, index) => element === array2[index])
  );
}

export function numberSpacing(number, spacing = 3) {
  let spacingRegex = `/\\B(?=(\\d{${spacing}})+(?!\\d))/g`;

  return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") ?? 0;
}

// color utils
export function convertToColorString(rgb) {
  if (
    !rgb ||
    typeof rgb !== "object" ||
    !("r" in rgb && "g" in rgb && "b" in rgb)
  ) {
    console.error("Invalid RGB object:", rgb);
    return "0,0,0,1"; // Fallback to a default color
  }
  return `${rgb.r},${rgb.g},${rgb.b},${rgb.a || 1}`;
}

// Function to parse and format color
export function parseAndFormatColor(colorString) {
  const [r, g, b, a] = colorString.split(",").map(Number);
  return { r, g, b, a };
}
