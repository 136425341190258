<script>
  import CurrencyAmount from "$lib/admin/components/blocks/helpers/CurrencyAmount.svelte";
  import BonusTurnover from "./BonusTurnover.svelte";
  import FreeSpinsTurnover from "./FreeSpinsTurnover.svelte";
  export let operator;
  export let project;

  let bonusAmount =
    operator?.bonus?.casino?.[$project.settings.market]?.["bonus_amount"];
  let bonusFallback =
    operator?.bonus?.casino?.[$project.settings.market]?.["bonus_fallback"];
  let freeSpinsAmount =
    operator?.bonus?.casino?.[$project.settings.market]?.["freespins_amount"];
  let freeSpinsFallback =
    operator?.bonus?.casino?.[$project.settings.market]?.["freespins_fallback"];
</script>

<div class="bonus-container">
  <h3 class="name">{operator.name}</h3>
  {#if bonusAmount}
    <div class="bonus">
      <span class="amount"><CurrencyAmount amount={bonusAmount} /></span>
      <span>Bonus</span>
    </div>
  {:else if bonusFallback}
    <div class="bonus">
      <span class="fallback">{bonusFallback}</span>
    </div>
  {/if}

  {#if freeSpinsAmount}
    <div class="freespins">
      <span class="amount">{freeSpinsAmount}</span> <span>Free Spins</span>
    </div>
  {:else if freeSpinsFallback}
    <div class="freespins">
      <span class="fallback">{freeSpinsFallback}</span>
    </div>
  {/if}

  <div class="bonus-wagering">
    <BonusTurnover
      bonusData={operator.bonus}
      marketSetting={$project.settings.market}
    />
    <FreeSpinsTurnover {operator} {project} />
  </div>
</div>

<style>
  .bonus-container {
    grid-area: BONUS;
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin: 8px;
    @media only screen and (min-width: 992px) {
      width: 100%;
      padding: 10px;
      margin: 0;
      max-width: 280px;
      justify-content: space-between;
    }
  }
  .bonus-container .name {
    display: none;
    margin: 0;
    font-size: 20px;
    text-align: left;
    color: #333333;
    @media only screen and (min-width: 992px) {
      display: block;
    }
  }
  .bonus,
  .freespins {
    font-weight: 300;
    font-size: 22px;
    line-height: 1.3;
    max-width: fit-content;
    padding: 2px 8px;
    border-radius: 10px;
    color: #333;
    background-color: #2ca5e41a;
    & span {
      font-size: 22px;
      @media only screen and (min-width: 992px) {
        font-size: 18px;
      }
      &.amount {
        font-weight: 600;
        color: var(--clr-qua-alt);
      }
      &.fallback {
        font-weight: 300;
        @media only screen and (min-width: 992px) {
          font-size: 16px;
        }
      }
    }
  }
  .freespins {
    background-color: #5cb6291a;
    & span {
      &.amount {
        color: #5cb629;
      }
    }
  }

  .bonus-container .bonus-wagering {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
</style>
