<script>
  import { orgOperators } from "$lib/services/store";
  import Terms from "$lib/admin/components/blocks/helpers/Terms.svelte";
  import Perks from "./blocks/Perks.svelte";
  import Logo from "./blocks/Logo.svelte";
  import Badges from "./blocks/Badges.svelte";
  import ClickToAction from "./blocks/ClickToAction.svelte";
  import Bonus from "./blocks/Bonus.svelte";
  import { translate } from "$lib/common/helpers/translate";

  export let props = null;
  export let operator = null;
  export let project = null;
  export let visibleOrNot = null;
  export let dataElement = null;

  let lang = $project.settings.lang ?? "en";
  let primaryColor =
    operator.visuals[$project.key][$project.settings.market].primary_color;

  $: formattedPrimaryColor = primaryColor.replace(/\s+/g, "");
  $: borderColor =
    formattedPrimaryColor === "255,255,255,1" ? "" : `rgba(${primaryColor})`;

  // Perks Data:

  // Freespins Min Deposit
  const bonusMinDeposit =
    operator?.bonus?.casino?.[$project.settings.market]
      .freespins_minimum_deposit ||
    operator?.bonus?.casino?.[$project.settings.market].bonus_min_deposit ||
    "-";

  // Wagering Games
  const wageringGamesRaw =
    operator?.bonus?.casino?.[$project.settings.market]
      .freespins_wagering_games || "-";

  const wageringGames =
    wageringGamesRaw.length > 50
      ? wageringGamesRaw.slice(0, 50) + "..."
      : wageringGamesRaw;

  // License
  const license = operator?.license_id?.name;
  // Shared License Operators
  const sharedLicenseOperators = $orgOperators.filter(
    (obj) =>
    obj?.license_id &&
    obj?.license_id?.id === operator?.license_id?.id &&
    obj.id != operator.id &&
    !obj.rating[$project.key]?.[$project.settings.market]?.["closed"] &&
    !obj.rating[$project.key]?.[$project.settings.market]?.["hide"]
    );

  // Convert sharedLicenseOperators to a string
  let sharedLicenseOperatorsNames = sharedLicenseOperators
    .map((operator) => operator.name)
    .join(", ");

  let perksData = [
    {
      title: translate("Bonus license", lang),
      value: license,
    },
    {
      title: translate("Shares license with", lang),
      value: sharedLicenseOperatorsNames || translate("No3", lang),
    },
    {
      title: translate("Bonus min deposit", lang),
      value: bonusMinDeposit,
    },
    {
      title: translate("Wagering games", lang),
      value: wageringGames,
    },
  ];
</script>

<div style="display: {visibleOrNot}">
  <div class="operator-container" style="border-color: {borderColor};">
    <Logo {operator} {primaryColor} {dataElement} />
    <Badges {operator} {project} />
    <Bonus {operator} {project} />
    <Perks perks={perksData} theme="freespins-bonus" />
    <ClickToAction {operator} {project} {props} {dataElement} />
  </div>
  <div class="terms-container">
    <Terms {operator} />
  </div>
</div>

<style>
  .operator-container {
    position: relative;
    display: grid;
    grid-template-columns: 125px 1fr;
    grid-template-rows: 0fr 55px 0fr 1fr;
    grid-template-areas:
      "LOGO BONUS"
      "LOGO BADGES"
      "LOGO CTA"
      "PERKS PERKS";
    border-radius: 10px;
    background-color: #fff;
    border: 2px solid rgba(217, 217, 217, 1);
    overflow: hidden;
    @media only screen and (min-width: 992px) {
      height: 190px;
      grid-auto-flow: column;
      grid-template-columns: 165px 55px 1.2fr 1.7fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
      grid-template-areas:
        "LOGO BADGES BONUS PERKS CTA"
        "LOGO BADGES BONUS PERKS CTA"
        "LOGO BADGES BONUS PERKS CTA";
    }
  }
  .terms-container {
    text-align: center;
    font-size: 12px;
    font-weight: 300;
    & a {
      color: var(--clr-qua);
      text-decoration: underline;
      font-weight: 300;
    }
  }
</style>
