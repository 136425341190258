type ExperienceItem = number | { result: number };
type RankingItem = { rating_total: number; rating_value: number };
type CalculationType = "experience" | "ranking";

function isRankingItem(
  item: ExperienceItem | RankingItem
): item is RankingItem {
  return (item as RankingItem).rating_total !== undefined;
}

export function calculateAverage(
  items: Array<ExperienceItem | RankingItem>,
  type: CalculationType
): number | string {
  let total = 0;
  let count = 0;

  items.forEach((item) => {
    if (type === "experience") {
      const value =
        typeof item === "object" ? (item as { result: number }).result : item;
      if (typeof value === "number" && !isNaN(value)) {
        total += value;
        count++;
      }
    } else if (type === "ranking" && isRankingItem(item)) {
      const { rating_total, rating_value } = item;
      if (
        typeof rating_value === "number" &&
        typeof rating_total === "number" &&
        rating_total !== 0
      ) {
        let normalizedScore = (rating_value / rating_total) * 5;
        total += normalizedScore;
        count++;
      }
    }
  });

  if (count > 0) {
    let average = total / count;
    return type === "ranking" ? +average.toFixed(1) : average;
  }

  return "-";
}
