<script>
  import { project } from "$lib/services/store";
  export let operator = null;
  export let blackColor = false;

  let minRating = 0;
  let maxRating = 5;

  let rating =
    operator?.rating?.[$project.key]?.[$project.settings.market]?.rating ??
    minRating;
  let ratingMessage = operator?.rating?.[$project.key]?.rating
    ? $project.name +
      " rates " +
      operator.name +
      " " +
      operator?.rating?.[$project.key]?.rating +
      " out of " +
      maxRating +
      " stars"
    : operator?.name + " has not yet been given a rating by " + $project.name;

  const defaultStarBgColor = "0, 0, 0, 0"; // Fallback star color
  const defaultStarColor = "230, 230, 230, 1"; // Fallback text color

  // Function to get operator color (primary or secondary)
  function getColor(type = "primary", defaultColor = "0, 0, 0, 1") {
    if (blackColor) {
      return "rgba(0, 0, 0, 1)"; // blackColor prop
    }
    const colorKey = type === "primary" ? "primary_color" : "secondary_color";
    const color =
      operator.visuals?.[$project.key]?.[$project.settings.market]?.[colorKey];
    return color ? `rgba(${color})` : `rgba(${defaultColor})`;
  }

  const starBgColor = getColor("primary", defaultStarBgColor); // --star-color uses the primary color or black
  const starColor = getColor("secondary", defaultStarColor); // Text color uses the secondary color
</script>

<div
  class="item-rating"
  style="--rating: {rating}; --star-color: {starBgColor}; color: {starColor} "
  aria-label={ratingMessage}
  title={ratingMessage}
>
  <span>★★★★★</span>
</div>
